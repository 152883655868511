$active-color: white;

$hover-color: #ffffffcc;

$idle-width: 100px;

#admin-interface {
  background-color: #efefef;
  min-height: 100vh;

  > nav {
    img {
      padding: 10px;
      width: 80px;
      align-self: center;
      justify-self: center;
    }

    background-color: #22223b;
    min-height: 100vh;
    height: 100%;
    width: $idle-width;
    transition: width 0.5s;
    overflow: hidden;
    box-shadow: 0 0 10px #00000070;
    z-index: 20;
    position: fixed;

    display: flex;
    flex-direction: column;

    // Uncomment this to force :hover
    // width: 250px;
    &:hover {
      width: 250px;
    }

    > .header {
      height: 75px;
      // background: #205740;
      margin-bottom: 1rem;

      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        &:hover {
          .icon {
            transform: scale(1.1);
          }
        }

        .icon {
          transition: transform 0.2s;
          width: 40px;
        }
      }
    }

    > .navigation {
      .nav-category {
        &:hover {
          > .nav-icon-container {
            opacity: 0.7;
          }

          > .nav-label {
            color: $hover-color;
          }
        }
        &.active {
          > .nav-icon-container {
            opacity: 1;
          }

          > .nav-label {
            color: $active-color;
          }

          > .nav-icon-container > .select-indicator {
            visibility: visible;
            opacity: 1;
            transform: translateX(0);
          }
        }
      }
    }
    > .navigation,
    > .footer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .nav-category {
        display: flex;
        align-items: center;
        width: $idle-width;
        cursor: pointer;

        > .nav-icon-container {
          opacity: 0.5;
          margin: 1rem 0rem;
          flex: 0 0 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          > .unread-messages-badge {
            left: 25%;
          }

          > .select-indicator {
            visibility: hidden;
            transform: translateX(-10px);
            opacity: 0;
            width: 5px;
            background-color: white;
            height: 50px;
            position: absolute;
            left: 0;
            transition: transform 0.2s, opacity 0.3s, visibility 0.2s;
          }

          > svg {
            width: 40px;
            height: 50px;

            > path {
              transition: fill 0.2s;
            }
          }
        }

        > .nav-label {
          color: #fff9;
          font-size: 1.5rem;
          font-weight: 400;
          transition: color 0.2s;
          white-space: pre-wrap;
          line-height: normal;
        }
      }
    }

    > .footer {
      margin-top: auto;
      color: white !important;

      .nav-category {
        > .nav-icon-container {
          opacity: 1;
          flex-direction: column;
          > .current-time {
            font-size: 26px;
            margin-bottom: 10px;
            margin-top: 10px;
          }
          > .user-name {
            opacity: 1;
            font-weight: 900;
          }
          > span {
            opacity: 0.6;
          }
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }

  > .content {
    margin-left: 100px;
  }
}
