#users {
  > .header {
    padding: 1rem 2rem;
    h2 {
      margin: 0 !important;
    }
  }

  .data-container {
    background: white;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    padding: 1rem;
    overflow-y: auto;
    margin: 1rem;
    &.no-padding {
      padding: 0;
    }
  }

  .with-margin {
    margin: 0.5rem 1rem;
  }
}

.user-orders {
  .header {
    margin: 0 !important;
  }

  padding: 1rem;

  position: relative;
  .selected-order-parent {
    width: 40vw;

    .selected-order-container {
      position: fixed;
      width: inherit;
      right: 0;
      top: 0;
      height: 100%;
      padding: 1rem;

      > .create-order-button {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }

      > .selectedOrder {
        background: #ffffff;
        box-shadow: 0px 4px 4px rgba(117, 117, 117, 0.25);
        border-radius: 10px;
        height: 100%;
        position: relative;
        z-index: 5;

        animation: slide-in 0.5s;
        -webkit-animation: slide-in 0.5s;

        > .header {
          background-color: #4a4e69;
          border-radius: 10px 10px 0px 0px;
          color: white;
          padding: 15px 20px;
          min-height: 70px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          h2 {
            margin: 0;
          }

          > .status {
            background-color: white;
            color: #4a4e69;
            padding: 5px 10px;
            border-radius: 50px;
            font-size: 17px;
            font-weight: 700;
          }

          > .close-button {
            margin: 0;
          }
        }

        > .content {
          height: calc(100% - 140px);
          overflow: auto;

          > .category {
            padding: 15px 20px;
          }
          > .products {
            padding-bottom: 0;

            h4 {
              margin-bottom: 5px;
            }

            .restaurant-products {
              display: flex;
              flex-direction: column;
              margin-bottom: 10px;

              > .product {
                margin-bottom: 10px;

                > .quantity {
                  margin-right: 10px;
                  font-weight: bold;
                }
                > .options {
                  padding-left: 30px;

                  .category {
                    opacity: 0.5;
                  }
                }
              }
            }
          }

          > .category {
            > .name {
              font-weight: 700;
            }

            > .informations-container {
              display: flex;
              justify-content: space-between;

              > .col {
                > .row {
                  display: flex;
                  flex-direction: column;
                  margin-bottom: 10px;

                  > .title {
                    font-weight: bold;
                  }
                }
              }
            }
          }

          .separator {
            border-top: 1px solid #e6e6e6;
          }
        }
        > .footer {
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 70px;
          background-color: #f5f5f5;

          > .actions {
            padding: 10px;
            display: flex;
            height: 100%;
            flex-direction: row-reverse;

            > button {
              margin-left: 0;
              margin-right: 3.5px;
            }
          }

          .separator {
            border-top: 1px solid #e6e6e6;
          }
        }
      }
    }
  }
}
