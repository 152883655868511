#restaurants,
#products {
  > .header {
    padding: 1rem 2rem;
    > div {
      height: 50px;
    }
  }

  .data-container {
    background: white;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    padding: 1rem;
    overflow-y: auto;
    margin: 1rem;
    &.no-padding {
      padding: 0;
    }
  }

  .with-margin {
    margin: 0.5rem 1rem;
  }

  img.restaurant-image,
  img.product-image,
  img.product-image {
    width: 80px;
    align-self: center;
    justify-self: center;
  }

  .product-show {
    .preview-image {
      width: 300px;
      margin-top: 5px;
    }

    .category-label {
      opacity: 0.5;
      font-size: 0.9rem;
    }

    .prices,
    .availability,
    .description {
      margin-top: 1rem;
    }

    .description {
      .centent {
        opacity: 0.8;
      }
    }
  }

  .option-row {
    padding: 1rem 2rem;
    font-size: 1.2rem;
    line-height: 2rem;

    .label {
      opacity: 0.7;
    }
  }

  .options-header {
    > h3 {
      padding: 0;
    }
    padding: 1rem 2rem;
    padding-top: 2rem;
  }
}

.password-reveal {
  font-size: 2rem;
  font-weight: 700;
  background-color: white;
  color: #242424;
  width: fit-content;
  padding: 1rem 2rem;
  border-radius: 1rem;
}

.options-search-modal {
  .content {
    min-height: 30rem;

    .search-input {
      margin-bottom: 2rem;
    }
  }
  .result {
    .option-row {
      padding: 0.5rem 2rem;
      font-size: 1.2rem;
      line-height: 2rem;

      .label {
        opacity: 0.7;
      }
    }
  }
}
.options-modal {
  overflow: hidden;

  .choice-range-input {
    > input {
      width: 100% !important;
    }
  }

  .choice-row {
    margin: 0.5rem 0;

    &:nth-child(2) {
      margin-top: 1.5rem;
    }

    .choice-price-input {
      flex-basis: 20%;
      min-width: 100px;
    }
  }
}
