#delivery-areas {
  > .header {
    padding: 2rem;
  }

  .list {
    flex-basis: 60%;
  }
  .map-container {
    height: 400px;
    width: 100%;
    flex-basis: 40%;
  }

  .data-container {
    background: white;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    padding: 1rem;
    overflow-y: auto;
    margin: 1rem 2rem;
    &.no-padding {
      padding: 0;
    }
  }

  .area-card {
    border: 1px solid #ececec;
    border-radius: 1rem;
    padding: 1.5rem 2rem;
    margin-bottom: 1rem;

    position: relative;
    overflow: hidden;

    .label {
      font-size: 1.5rem;
    }

    .color {
      background-color: rgb(0, 0, 0);
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 10px;
    }

    .area-parameters {
      margin-top: 0.7rem;
      .area-parameter {
        > svg {
          height: 1.5rem;
          width: 2.2rem;
        }
        > .small-margin {
          margin-left: 5px;
        }
      }
    }
  }
}

.delivery-area-modal {
  .map-container {
    height: 400px;
  }
}
