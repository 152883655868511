@keyframes slide-in {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

#orders {
  // height: calc(100vh - 70px);
  height: 100vh;
  > .content {
    height: 100%;

    display: flex;

    .unacknowledged-restaurant-order-tag,
    .countdown,
    .time-display {
      height: fit-content;
      width: min-content;
      padding: 0.2rem 0.7rem;
      border-radius: 0.4rem;
      background-color: #bc8867;
      color: white;
      font-weight: 800;
      align-self: center;
      white-space: nowrap;
    }

    .countdown,
    .time-display {
      background-color: #67bc75;
      &.nearly-late {
        background-color: #bca667;
      }
      &.late {
        background-color: #bc6767;
      }
    }

    > .board {
      flex-basis: 65%;
      display: flex;
      padding-left: 0.5rem;
      padding-top: 0.5rem;

      .order-cards {
        width: 100%;
        margin-right: 1rem;

        .order-rows {
          overflow-y: auto;
          max-height: calc(100% - 54px);
        }

        a {
          height: 40px;
          .circular-count {
            margin-left: 0.5rem !important;
          }
        }
      }

      > .board-col {
        flex: 0 0 33%;
        display: flex;
        flex-direction: column;
        padding: 0px 10px;
        overflow-y: auto;
        overflow-x: hidden;

        > .board-card {
          background: white;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
          border-radius: 5px;
          padding: 10px 15px;
          margin-bottom: 15px;
          transition: transform 0.2s, background 0.2s;
          cursor: pointer;
          &.selected {
            background: #eef2ff;
          }
          &:hover {
            transform: scale(1.07);
          }

          > .card-header {
            h2 {
              margin-bottom: 0 !important;
            }
            margin-bottom: 10px;
            display: flex;
            align-items: center;

            > .timer {
              margin-left: auto;
            }
          }

          > .card-row {
            margin-bottom: 2px;
            > .label {
              margin-right: 5px;
              font-family: Roboto;
              font-weight: 500;
              font-size: 15px;
              color: rgba(0, 0, 0, 0.35);
            }
            > .value {
              font-family: Roboto;
              font-weight: 500;
              font-size: 15px;
            }
          }
        }
      }
    }

    > .selected-order-container {
      flex-basis: 35%;
      padding: 0;
      position: relative;

      > .create-order-button {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }

      > .selectedOrder {
        background: #ffffff;
        box-shadow: 0px 4px 4px rgba(117, 117, 117, 0.25);
        border-radius: 10px;
        height: 100%;
        position: relative;
        z-index: 5;

        animation: slide-in 0.5s;
        -webkit-animation: slide-in 0.5s;

        > .header {
          background-color: #4a4e69;
          border-radius: 10px 10px 0px 0px;
          color: white;
          padding: 15px 20px;
          min-height: 70px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          h2 {
            margin: 0;
          }

          > .status {
            background-color: white;
            color: #4a4e69;
            padding: 5px 10px;
            border-radius: 50px;
            font-size: 17px;
            font-weight: 700;
          }

          > .close-button {
            margin: 0;
          }
        }

        > .content {
          height: calc(100% - 140px);
          overflow: auto;

          > .category {
            padding: 15px 20px;
          }
          > .products {
            // padding: 15px 20px;
            padding-bottom: 0;

            h4 {
              margin-bottom: 5px;
            }

            .restaurant-products {
              display: flex;
              flex-direction: column;
              margin-bottom: 10px;

              > .product {
                margin-bottom: 10px;

                > .quantity {
                  margin-right: 10px;
                  font-weight: bold;
                }
                > .options {
                  padding-left: 30px;

                  .category {
                    opacity: 0.5;
                  }
                }
              }
            }
          }

          > .category {
            > .name {
              font-weight: 700;
            }

            > .informations-container {
              display: flex;
              justify-content: space-between;

              > .col {
                // flex: 0 0 48%;

                > .row {
                  display: flex;
                  flex-direction: column;
                  margin-bottom: 10px;

                  > .title {
                    font-weight: bold;
                  }
                }
              }
            }
          }

          .separator {
            border-top: 1px solid #e6e6e6;
          }
        }
        > .footer {
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 70px;
          background-color: #f5f5f5;

          > .actions {
            padding: 10px;
            display: flex;
            height: 100%;
            flex-direction: row-reverse;

            > button {
              margin-left: 0;
              margin-right: 3.5px;
            }
          }

          .separator {
            border-top: 1px solid #e6e6e6;
          }
        }
      }
    }
  }

  .data-container {
    background: white;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    padding: 1rem;
    overflow-y: auto;
    &.no-padding {
      padding: 0;
    }
  }
}

.time-picker-inline {
  display: inline-block;
}

.toast-order-creation {
  display: flex;
  gap: 1rem;
  align-items: center;

  .order-number {
    font-size: 3rem;
    color: black;
  }
}

.order-received-toast {
  background: #e4e4ea !important;
  border: 2px solid #9797df;
}

.product-modal {
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.85);
  -webkit-animation: 0.2s ease-in-out 0s fade-in;
  animation: 0.2s ease-in-out 0s fade-in;
  z-index: 1000;

  > .content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0 1rem;
    padding-bottom: 1rem;
    border: 1px solid #888;
    width: 480px;
    max-height: 80%;
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation: 0.1s linear 0s animate-top, 0.1s ease-out 0s fade-in;
    animation: 0.1s linear 0s animate-top, 0.1s ease-out 0s fade-in;

    @media all and (max-width: 480px) {
      width: 100%;
      max-height: none;
      height: 90%;
    }

    > .header {
      position: relative;
      .image-container {
        border-radius: 8px;
        overflow: hidden;
        background-color: #efefef;
        position: relative;
        width: 200px;
        height: 150px;
        -webkit-transform: translateY(-2rem);
        transform: translateY(-2rem);
        margin-bottom: -2rem;
        box-shadow: 0 4px 10px 0 rgba(255, 255, 255, 1);
      }

      .close-icon {
        position: absolute;
        top: 0;
        right: 0;

        font-size: 1rem;
        padding-top: 3px;
        border-radius: 50%;
        cursor: pointer;
      }

      .title {
        font-size: 2rem;
        font-weight: 800;
      }

      .ingredients {
        color: #757575;
      }
    }

    > .body {
      overflow-x: auto;
      margin-left: -1rem;
      padding: 1rem;
      padding-right: 0rem;
      margin-right: -1rem;
      background: #f5f5f5;
      box-shadow: inset 0px 11px 8px -10px #909090,
        inset 0px -11px 8px -10px #909090;

      .options {
        padding-right: 1rem;
        .option-label {
          b {
            font-size: 1.2rem;
          }
          :not(b) {
            opacity: 0.8;
            font-size: 0.8rem;
          }
        }
        .choices {
          display: flex;
          flex-wrap: wrap;
          padding: 1rem 0;

          > :nth-child(n) {
            flex: 1 0 50%;
          }

          .checkbox {
            // width: 100%;
          }
          .choice-price {
            background-color: #404073;
            color: white;
            margin-left: 0.5rem;
            padding: 0.2rem 0.4rem;
            border-radius: 5px;
            font-size: 0.8rem;
            display: inline-block;
            &:before {
              content: "+ ";
            }
          }
        }
      }

      .comment-label {
        font-size: 1.2rem;
      }
    }

    > .footer {
      margin-top: auto !important;

      .desired-quantity {
        display: flex;
        align-items: center;
        width: 1rem;
        font-size: 1.5rem;
        justify-content: center;
      }

      .add-product-button {
        cursor: pointer;
        border-radius: 15px;
        border: none;
        font-weight: 900;
        background-color: #353565;
        color: white;
        box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
        padding: 1rem;
        &:hover {
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        }
      }
    }
  }
}

.cart-summary {
  display: flex;
  flex-direction: column;
  width: 100%;
  $summary-padding: 1rem 1rem;

  .restaurant-products {
    overflow-y: auto;
    position: relative;
    @supports (-webkit-touch-callout: none) {
      -webkit-overflow-scrolling: touch;
    }
    .restaurant {
      .title {
        padding: $summary-padding;
        font-size: 1rem;
        .name {
          font-size: 1.3rem;
          font-weight: 700;
          margin-left: 0.3rem;
        }
      }

      .product {
        font-size: 1.4rem;
        transition: background-color 0.5s;
        padding: $summary-padding;

        .count {
          background-color: #22223b;
          width: 30px;
          height: 30px;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.1rem;
        }

        .name {
          font-size: 1.2rem;
        }

        .price-wrapper {
          position: relative;
          margin-left: auto;
          // padding-top: 10px;

          > .item-price {
            opacity: 1;
            transition: opacity 0.1s ease-in-out;
          }
          > .item-price-above {
            opacity: 0;
            transition: opacity 0.1s ease-in-out;
          }
        }
        .item-price,
        .item-price-above {
          position: absolute;
          right: 0px;

          .plus-button > button,
          .minus-button > button {
            box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.08);
            border-radius: 50%;
            width: 30px;
            height: 30px;
            font-size: 0.7rem;
            cursor: pointer;
          }

          .plus-button > button {
            background-color: #22223b;
            border: 2px #22223b solid;
            color: white;
          }
          .minus-button > button {
            margin-left: 0.5rem;
            background-color: transparent;
            border: 2px #22223b solid;
            color: #22223b;
          }
        }

        .item-additionnal-information {
          opacity: 0.5;
          padding-left: 0.5rem;
          font-size: 1rem;
          gap: 0.3rem;
          border-left: solid 1px #909090;
          padding: 0.4rem;
          margin-left: 1rem;

          .item-options {
            font-weight: 700;
          }

          .option-choices {
            font-weight: 400;
          }

          .comments {
            font-size: 0.9rem;
          }
        }

        &.interactive:hover {
          background-color: #2121a933;
          transition: background-color 0.5s;

          .item-price.hoverable {
            opacity: 0;
          }
          .item-price-above {
            opacity: 1;
          }
        }
      }
    }
  }

  .cart-prices {
    padding: $summary-padding;
    padding-bottom: 0.5rem;

    .price-row {
      margin-bottom: 0.5rem;
      .title {
        font-size: 1.2rem;
      }
      .price {
        font-size: 1.2rem;
      }
    }
  }

  .delivery-address {
    background: #20335b;
    color: white;
    padding: 0.7rem 1rem;
    margin: 0;

    .address-header {
      opacity: 0.8;
      font-size: 0.8rem;
    }
  }

  .address-search-bar {
    .input {
      width: 100%;
    }
    input {
      border: 2px transparent solid !important;
    }
    &.error {
      input {
        border: 2px #f2746e solid !important;
      }
    }
  }

  &.inverted {
    color: #ededed;

    .count {
      background-color: #d2d5d9 !important;
      color: #1f345b !important;
      font-weight: 500;
    }
    .separator {
      opacity: 0.2;
    }

    .delivery-address {
      background: white;
      color: #20335b;
    }
  }
}

.product-selection {
  .category-products {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2%;
    .product-card {
      flex-basis: 49%;
      background: #f8f8f8;
      box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 15%);
      border-radius: 5px;
      padding: 0.7rem 1rem;
      margin-bottom: 15px;
      cursor: pointer;
    }
  }
}

#cancel-product-modal {
  .product-row {
    white-space: nowrap;

    .removed {
      text-decoration: line-through;
      opacity: 0.5;
    }
  }
}
