.column {
  display: flex;
  flex-direction: column;

  &.children-margin {
    &.tiny {
      > :nth-child(n) {
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;
      }
    }
    &.small {
      > :nth-child(n) {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }
    &.medium {
      > :nth-child(n) {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }
    &.large {
      > :nth-child(n) {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
      }
    }

    > :first-child {
      margin-top: 0 !important;
    }
    > :last-child {
      margin-bottom: 0 !important;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;

  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6 {
    margin-top: 0;
    margin-bottom: 0;
  }

  &.children-margin {
    &.tiny {
      > :nth-child(n) {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }
    }
    &.small {
      > :nth-child(n) {
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }
    &.medium {
      > :nth-child(n) {
        margin-left: 2rem;
        margin-right: 2rem;
      }
    }
    &.large {
      > :nth-child(n) {
        margin-left: 3rem;
        margin-right: 3rem;
      }
    }

    > :first-child {
      margin-left: 0 !important;
    }
    > :last-child {
      margin-right: 0 !important;
    }
  }

  &.last-child-on-end {
    > :last-child {
      margin-left: auto !important;
    }
  }
}

.separator {
  border-top: 1px solid #e6e6e6;

  &.vertical {
    height: auto;
    margin: 0 2rem;
    border-top: none;
    border-left: 1px solid #e6e6e6;
  }
  &.dark {
    border-top: 1px solid #d1d1d1;
  }
  &.darker {
    border-top: 1px solid #aaaaaa;
  }
}

.separator-with-children {
  display: flex;
  align-items: center;
  div {
    width: 100%;
  }
  span {
    margin: 0 0.75rem;
    text-transform: uppercase;
    font-weight: 600;
  }
}

.clickable {
  cursor: pointer;
}

.hoverable {
  transition: background 0.1s;
  &:hover {
    background: #eaf7ff !important;
  }
}

.min-height {
  &.medium {
    min-height: 30rem;
  }
}

.no-data {
  opacity: 0.3;
  &.center {
    text-align: center;
  }
}

.image-uploader {
  position: relative;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .image-placeholder {
    background: #35355f1a;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .error-overlay {
    position: absolute;
    border: 1px solid #dfb4b4;
    border-radius: 10px;
    background: #fef5f699;
    height: 100%;
    width: 100%;
    opacity: 0.6;
  }
  .upload-overlay {
    position: absolute;
    background: #22223b;
    border-radius: 10px;
    height: 100%;
    width: 100%;
    transition: opacity 0.2s;
    opacity: 0;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    .upload-overlay {
      opacity: 0.6;
    }
  }

  .preview-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.ui.dimmer .ui.workaround-modal-loader.loader:before {
  border-color: rgba(0, 0, 0, 0.1);
}

.ui.dimmer .ui.workaround-modal-loader.loader:after {
  border-color: #767676 transparent transparent;
}

.ui.dimmer .ui.workaround-modal-loader.loader {
  color: black;
}

.hour-range-editor {
  background: #cacaca;
  padding: 5px 10px;
  border-radius: 5px;

  input {
    width: 100px !important;
  }

  > div {
    margin-bottom: 0 !important;
  }

  input:invalid {
    background: red;
  }

  .submit-hour-range {
    margin-left: 5px !important;
    height: 40px;
    align-self: flex-end;
  }
}

.week-opening-hours {
  .day-row {
    margin-bottom: 10px;

    .day-title {
      font-weight: 800;
      margin-right: 10px;
    }

    .hour-range {
      margin: 5px;
      background: #828282;
      padding: 5px 10px;
      border-radius: 6px;
      color: white;
      font-weight: 800;
      white-space: nowrap;
      cursor: pointer;
      &:hover {
        background: #717171;
      }

      .entry {
        &.start {
          &::after {
            content: " - ";
          }
        }
      }

      .remove-icon {
        margin-left: 5px;
        margin-right: 0;
        color: #dadada;
      }
    }

    .add-hour-range {
      margin-left: 5px;
    }
  }
}

@keyframes grow-and-fade {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(2);
  }
}

.ping {
  background-color: #3d3d7a;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  position: relative;
  flex-shrink: 0;
  .animated {
    position: absolute;
    background-color: #3d3d7a;
    border-radius: 10px;
    width: 10px;
    height: 10px;
    animation: grow-and-fade 1s infinite;
  }
  &.slow {
    .animated {
      animation: grow-and-fade 1.5s infinite;
    }
  }
  &.fast {
    .animated {
      animation: grow-and-fade 0.5s infinite;
    }
  }
  &.fastest {
    .animated {
      animation: grow-and-fade 0.3s infinite;
    }
  }
}

.order-products {
  display: flex;
  flex-direction: column;
  width: 100%;
  $summary-padding: 0.5rem 1rem;

  .restaurant-products {
    overflow-y: auto;
    .restaurant {
      .title {
        padding: $summary-padding;
        font-size: 1rem;
        .name {
          font-size: 1.3rem;
          font-weight: 700;
          margin-left: 0.3rem;
        }

        .order-restaurant-cancel-button {
          border-radius: 50%;
          border: none;
          width: 25px;
          height: 25px;
          background-color: #d64747;
          color: white;
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 0.3rem;
          cursor: pointer;
          i {
            margin: 0 !important;
          }
          &:hover {
            background-color: #b13f3f;
          }
        }
      }

      .product {
        font-size: 1.4rem;
        transition: background-color 0.5s;
        padding: $summary-padding;

        &.canceled {
          background-color: #ffdede;
          .name {
            text-decoration: line-through;
          }
        }

        .count {
          background-color: #22223b;
          width: 30px;
          height: 30px;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.1rem;
          font-weight: 700;
        }

        .name {
          font-size: 1.2rem;
        }

        .price-wrapper {
          position: relative;
          margin-left: auto;

          > .item-price {
            opacity: 1;
            transition: opacity 0.1s ease-in-out;
          }
        }
        .item-price {
          position: absolute;
          right: 0px;
        }

        .item-additionnal-information {
          opacity: 0.5;
          padding-left: 0.5rem;
          font-size: 1rem;
          gap: 0.3rem;
          border-left: solid 1px #909090;
          padding: 0.4rem;
          margin-left: 1rem;

          .item-options {
            font-weight: 700;
          }

          .option-choices {
            font-weight: 400;
          }

          .comments {
            font-size: 0.9rem;
          }
        }
      }
    }
  }

  .cart-prices {
    padding: $summary-padding;
    padding-bottom: 0.5rem;

    .price-row {
      margin-bottom: 0.5rem;
      .title {
        font-size: 1.2rem;
      }
      .price {
        font-size: 1.2rem;
      }
    }
  }

  &.inverted {
    color: #ededed;

    .count {
      background-color: #d2d5d9 !important;
      color: #1f345b !important;
      font-weight: 500;
    }
    .separator {
      opacity: 0.2;
    }
  }
}

.order-status {
  border-radius: 12px;
  border: 3px solid white;
  font-weight: 500;
  padding: 0.2rem 0.6rem;
  font-size: 0.9rem;

  height: fit-content;
  width: min-content;
  &.large {
    padding: 0.3rem 1rem;
    font-size: 1.2rem;
    border-radius: 18px;
  }
}

.payment-status {
  border-radius: 12px;
  border: 3px solid white;
  padding: 0.2rem 0.6rem;
  font-size: 0.9rem;
  font-weight: 500;
  width: fit-content;
  &.large {
    padding: 0.3rem 1rem;
    font-size: 1.2rem;
    border-radius: 18px;
  }
}

.order-card {
  background: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 5px;
  padding: 1rem 2rem;
  margin: 0 5px;
  margin-bottom: 15px;
  position: relative;
  overflow: hidden;
  &.selected {
    background: #c1d7ff !important;
  }

  .border-color {
    position: absolute;
    width: 10px;
    height: 100%;
    left: 0;
    top: 0;

    display: flex;
    align-items: center;
    font-weight: 800;
    color: transparent;
    z-index: 1;

    &:hover {
      color: white;
      width: fit-content;
      padding: 0 1rem;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    }
  }

  .number {
    font-size: 3rem;
    align-self: center;
    flex-basis: 4rem;
  }
  .customer {
    font-weight: 700;
    font-size: 1.2rem;
  }
  .address,
  .city {
    opacity: 0.5;
  }
  .total {
    font-weight: 700;
    font-size: 1.2rem;
  }
  .payment-method {
  }
  .attribution-button {
    margin-left: auto !important;
  }

  .status {
    align-self: center;
    margin-left: auto;
  }
}

.circular-button {
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.2);
  background-color: #353565;
  border: 2px #353565 solid;
  color: white;
  border-radius: 50%;
  cursor: pointer;
  width: 40px;
  height: 40px;
  font-size: 1.7rem;
  padding: 0;
  font-weight: 900;
  padding-bottom: 2px;
  transition: opacity 0.2s;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.inverted {
    background-color: transparent;
    border: 3px #353565 solid;
    color: #353565;
  }
  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.3;
  }
}

.circular-count {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.5rem;
  min-width: 1.5rem;
  max-width: 1.5rem;
  padding: 0.4rem;
  border-radius: 50%;
  background-color: #3d3d7a;
  color: white;
  font-size: 0.7rem;
  font-weight: 700;
}

.squared-count {
  background-color: #22223b;
  min-width: 30px;
  height: 30px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  font-weight: 700;
}

.bp5-portal {
  z-index: 1000;
}
