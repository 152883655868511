#discount-codes {
  > .header {
    padding: 1rem 2rem;
    h2 {
      margin: 0 !important;
    }
  }
  .data-container {
    background: white;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    padding: 1rem;
    overflow-y: auto;
    margin: 1rem;
    &.no-padding {
      padding: 0;
    }
  }
}

// Fix modal overlaying date-picker
.bp3-portal {
  z-index: 1001;
}

.date-picker-input input {
  height: 38px;
}
.discount-code-modal {
  .users-list {
    flex-wrap: wrap;
    .user-label {
      margin: 0.2rem 0.3rem;
    }
  }
}
