#login-page {
  display: flex;
  flex-direction: column;
  background-color: #22223b;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  padding-bottom: 150px;
}

#login-page img {
  max-width: 200px;
}
