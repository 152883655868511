#invoice-exports {
  padding: 1rem 2rem;

  > div {
    margin: 1rem 0;
  }
}

#restaurants-profit-analysis {
  .bp3-control-group .bp3-input {
    height: 38px;
  }
}

#deliveryman-analysis,
#restaurants-profit-analysis {
  .board {
    flex-basis: 65%;
    padding: 1rem;
    padding-top: 0;
    height: 100vh;
    overflow-y: auto;
  }

  .deliveryman-summary-prices {
    white-space: nowrap;
  }

  .selected-order-container {
    flex-basis: 35%;
    padding: 1rem;

    > .selectedOrder {
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(117, 117, 117, 0.25);
      border-radius: 10px;
      height: 100%;
      position: relative;

      animation: slide-in 0.5s;
      -webkit-animation: slide-in 0.5s;

      > .header {
        background-color: #4a4e69;
        border-radius: 10px 10px 0px 0px;
        color: white;
        padding: 15px 20px;
        min-height: 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h2 {
          margin: 0;
        }

        > .status {
          background-color: white;
          color: #4a4e69;
          padding: 5px 10px;
          border-radius: 50px;
          font-size: 17px;
          font-weight: 700;
        }
      }

      > .content {
        height: calc(100% - 140px);
        overflow: auto;

        > .category {
          padding: 15px 20px;
        }
        > .products {
          // padding: 15px 20px;
          padding-bottom: 0;

          h4 {
            margin-bottom: 5px;
          }

          .restaurant-products {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;

            > .product {
              margin-bottom: 10px;

              > .quantity {
                margin-right: 10px;
                font-weight: bold;
              }
              > .options {
                padding-left: 30px;

                .category {
                  opacity: 0.5;
                }
              }
            }
          }
        }

        > .category {
          > .name {
            font-weight: 700;
          }

          > .informations-container {
            display: flex;
            justify-content: space-between;

            > .col {
              // flex: 0 0 48%;

              > .row {
                display: flex;
                flex-direction: column;
                margin-bottom: 10px;

                > .title {
                  font-weight: bold;
                }
              }
            }
          }
        }

        .separator {
          border-top: 1px solid #e6e6e6;
        }
      }
      > .footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 70px;
        background-color: #f5f5f5;

        > .actions {
          padding: 10px;
          display: flex;
          height: 100%;
          flex-direction: row-reverse;
        }

        .separator {
          border-top: 1px solid #e6e6e6;
        }
      }
    }
  }
}
